import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import Topbar from '../components/Topbar';
import { navigate } from 'gatsby';
import NavOne from '../components/NavOne';
import SliderOne from '../components/SliderOne';
import SliderTwo from '../components/SliderTwo';
import Footer from '../components/Footer';
import AboutTwo from '../components/AboutTwo';
import CourseOne from '../components/CourseOne';
import VideoTwo from '../components/VideoTwo';
import CountdownKipso from '../components/CountdownKipso';
import CourseCatOne from '../components/CourseCatOne';
import CallToActionThree from '../components/CallToActionThree';
import BrandsTwo from '../components/BrandsTwo';
import BlogTwo from '../components/BlogTwo';
import CallToActionTwo from '../components/CallToActionTwo';
import SubscribeOne from '../components/SubscribeOne';
import "bootstrap/dist/css/bootstrap.min.css";

const HomePage = () => {
  // useEffect(() => {
  //   navigate('/inprogress');
  // }, []);

  return (
    <Layout pageTitle='Lagos University Teaching Hospital LUTH'>
      {/* <Topbar /> */}
      <NavOne />
      <SliderTwo />
      <AboutTwo />

      <BlogTwo />
      {/* <CourseOne />
    <VideoTwo />
    <CountdownKipso />
    <CourseCatOne />
    <CallToActionThree />
    <BrandsTwo /> */}
      <CallToActionTwo />
      {/* <CallToActionFour /> */}
      {/* <SubscribeOne /> */}
      <Footer />
    </Layout>
  );
};

export default HomePage;
